const OperationEnum = {
  ACADEMIC_CALENDAR_ADMIN: "AC_1",
  ACADEMIC_CALENDAR_VIEWER: "AC_2",
  ANNOUNCEMENT_TEMPLATE_ADMIN: "ANN_1",
  ANNOUNCEMENT_ADMIN: "ANN_2",
  ANNOUNCEMENT_VIEWER: "ANN_3",
  ASSIGN_GENERAL_ADMIN: "AS_1",
  ASSIGN_GROUP_ADMIN: "AS_2",
  ASSIGN_CENTER_GROUP_ADMIN: "AS_3",
  ASSIGN_GENERAL_VIEWER: "AS_4",
  ASSIGN_GROUP_VIEWER: "AS_5",
  ASSIGN_CENTER_GROUP_VIEWER: "AS_6",
  CAMPUS_VIEWER: "CA_1",
  CENTER_GROUP_ADMIN: "CG_1",
  CENTER_GROUP_VIEWER: "CG_2",
  CLASS_ADMIN: "CLS_1",
  CLASS_EDITOR: "CLS_2",
  CLASS_VIEWER: "CLS_3",
  COMMUNITY_VIEWER: "CM_1",
  CONTRACT_ADMIN: "CN_1",
  CONTRACT_EDITOR: "CN_2",
  CONTRACT_VIEWER: "CN_3",
  CONTRACT_PRICE_VIEWER: "CN_4",
  CONTRACT_REPORT_VIEWER: "CN_5",
  CONTRACT_PAYMENT_REPORT_VIEWER: "CN_6",
  CONTRACT_QUOTA_REPORT_VIEWER: "CN_7",
  CONTRACT_ADDER: "CN_8",
  CORPORATION_VIEWER: "CO_1",
  CHANGE_PASSWORD: "CP_1",
  CURRICULUM_VIEWER: "CU_1",
  CURRICULUM_ADMIN: "CU_2", // domain'i system olan operasyon. Sadece admin verebilir!
  COURSE_ADMIN: "C_1",
  COURSE_VIEWER: "C_2",
  DASHBOARD_ADMIN: "DB_1",
  DISCOUNT_ITEM_ADMIN: "DI_1",
  DISCOUNT_ITEM_EDITOR: "DI_2",
  DISCOUNT_ITEM_VIEWER: "DI_3",
  DOMAIN_ROLE_EDITOR: "DRE_1",
  ETUDE_ADMIN: "ET_1",
  ETUDE_VIEWER: "ET_2",
  ETUDE_EDITOR: "ET_3",
  ETUDE_ATTENDANCE_ADMIN: "ET_4",
  FINANCIAL_ACCOUNT_ADMIN: "FA_1",
  FINANCIAL_ACCOUNT_EDITOR: "FA_2",
  FINANCIAL_ACCOUNT_VIEWER: "FA_3",
  FEE_ITEM_SET_ADMIN: "FIS_1",
  FEE_ITEM_SET_EDITOR: "FIS_2",
  FEE_ITEM_SET_VIEWER: "FIS_3",
  FEE_ITEM_ADMIN: "FI_1",
  FEE_ITEM_EDITOR: "FI_2",
  FEE_ITEM_VIEWER: "FI_3",
  FLOW_AREA_ADMIN: "FLA_1",
  FINANCIAL_YEAR_ADMIN: "FY_1",
  FINANCIAL_YEAR_EDITOR: "FY_2",
  FINANCIAL_YEAR_VIEWER: "FY_3",
  GROUP_ADMIN: "G_1",
  GROUP_EDITOR: "G_2",
  GROUP_VIEWER: "G_3",
  HOMEWORK_TYPE_ADMIN: "HT_1",
  HOMEWORK_ADMIN: "HW_1",
  HOMEWORK_VIEWER: "HW_2",
  LIVE_COURSE_ADMIN: "LC_1",
  LIVE_COURSE_VIEWER: "LC_2",
  LOG_VIEWER: "LG_1",
  MAIL_ADMIN: "MAI_1",
  MAIL_VIEWER: "MAI_2",
  PARENT_ADMIN: "PA_1",
  PARENT_EDITOR: "PA_2",
  PARENT_VIEWER: "PA_3",
  PARENT_ADDER: "PA_4",
  PRE_REGISTRATION_ADMIN: "PRE_1",
  PRE_REGISTRATION_VIEWER: "PRE_2",
  PERSONNEL_ADMIN: "PR_1",
  PERSONNEL_EDITOR: "PR_2",
  PERSONNEL_VIEWER: "PR_3",
  PAYMENT_ADMIN: "PY_1",
  PAYMENT_EDITOR: "PY_2",
  PAYMENT_VIEWER: "PY_3",
  PAYMENT_ADDER: "PY_4",
  SCHOOL_EDITOR: "SC_1",
  SCHOOL_VIEWER: "SC_2",
  SHARE_INFO_ADMIN: "SI_1",
  SMS_ADMIN: "SMS_1",
  SMS_EDITOR: "SMS_2",
  SMS_VIEWER: "SMS_3",
  SHARE_PACK_ADMIN: "SP_1",
  SHARE_PACK_VIEWER: "SP_2",
  STUDENT_ADMIN: "ST_1",
  STUDENT_EDITOR: "ST_2",
  STUDENT_VIEWER: "ST_3",
  STUDENT_ADDER: "ST_4",
  STUDENT_DELETE_ADMIN: "ST_5",
  USER_ADMIN: "US_1",
  USER_EDITOR: "US_2",
  WEEKLY_SCHEDULE_ADMIN: "WS_1",
  OVERDUE_INSTALLMENTS: "OV_1",
  SELECTION_ADMIN: "SEL_1",
  COMMON_STUDENT: "CMS_1",
  COMMON_PERSONNEL: "CMP_1",
  COMMON_CONSERVATOR: "CMC_1",
  INTERVIEW_ADMIN: "INT_1",
  INTERVIEW_VIEWER: "INT_2",
  CLUB_ADMIN: "CLU_1",
  CLUB_EDITOR: "CLU_2",
  CLUB_VIEWER: "CLU_3",
  CODE_EXAMY: "CE_1",
  ROOM_ADMIN: "RA_1",
  SITTING_PLAN_ADMIN: "SPA_1",
  SUPER_EXAM_ADMIN: "SEA_1",
  EXAM_ADMIN: "EX_1",
  EXAM_EDITOR: "EX_2",
  EXAM_VIEWER: "EX_3",
  ATTENDANCE_ADMIN: "AT_1",
  ATTENDANCE_VIEWER: "AT_2",
  BASIC_HOMEWORK_ADMIN: "BHA_1",
  BASIC_HOMEWORK_VIEWER: "BHA_2",
  FOOD_PROGRAM_ADMIN: "FP_1",
  FOOD_PROGRAM_VIEWER: "FP_2",
  NOTE_ADMIN: "NA_1",
  NOTE_VIEWER: "NA_2",
  TEST_ADMIN: "TE_1",
  TEST_VIEWER: "TE_2",
  DASHBOARD_SCHDULE: "DAS_1",
  DASHBOARD_STUDENTCOUNTS: "DAS_2",
  TRACKLIGHT_ADMIN: "TRL_1",
  TRACKLIGHT_EDITOR: "TRL_2",
  SURVEY_ADMIN: "SUR_1",
  SURVEY_EDITOR: "SUR_2",
  SURVEY_VIEWER: "SUR_3",
  OUTSIDE_VIEWER: "OUT_1",
  INVENTORY_ADMIN: "INV_1",
  ADDITIONAL_BUILDING_ADMIN: "AB_1",
  ADDITIONAL_BUILDING_VIEWER: "AB_2",
  INSTANT_MESSAGE_ADMIN: "IM_1",
  INTEGRATION_ADMIN: "IA_1",
  PDR_ADMIN: "PDR_1",
  LIVEXAMY_CHAT_ADMIN: "LEC_1",
  LIVEXAMY_CHANNEL_1_MODERATOR: "LCM_1",
  LIVEXAMY_CHANNEL_2_MODERATOR: "LCM_2",
  NEAR_POD_ADMIN: "NP_1",
  CASE_OPERATION_ADMIN: "CO_1",
  LIBRARY_ADMIN: "LB_1",
  LIBRARY_VIEWER: "LB_2",
  CAMPUS_WEEKLY_SCHEDULE_ADMIN: "CWS_1",
  PERSONAL_BOOK_ADMIN: "PB_1",
  USER_TWO_FACTOR_ADMIN: "UTF_1",
  SOLUTION_VIDEO_ADMIN: "SV_1",
  SELF_ID_HOMEWORK_ASSIGN: "SHM_1",
  SELF_ID_REPORT_TRACK: "SIR_1",
  QUESTION_POOL_ADMIN: "QP_1",
  SELFID_ADMIN: "SIA_1",
  A_TO_Z_ADMIN: "AZ_1",
  EARNING_COURSE_ADMIN: "EC_1",
  FIX_ACCOUNTING_CONTRACT_1: "FC_1",
  INTEGRATION_CAMPUS_ADMIN: "IA_2",
  SCHEDULER_ADMIN: "SCH_1",
  SCHOOL_ACTIVITY_ADMIN: "SAC_1",
  SCHOOL_ACTIVITY_EDITOR: "SAC_2",
  SCHOOL_ACTIVITY_VIEWER: "SAC_3",
  ABILITY_EVOLUTION_ADMIN: "AE_1",
  ABILITY_EVOLUTION_VIEWER: "AE_2",
  ABILITY_EVOLUTION_TEMPLATE_ADMIN: "AE_3",
  CASE_OPERATION_EDITOR: "COE_1"
};
Object.freeze(OperationEnum);

export { OperationEnum };
